<div id="transedit-header">
  <div id="transedit-header-title">{{ 'internal.inventory.transactionEdit.title' | translate }}</div>
</div>

<div id="transedit-form">
  <div id="transaction-location">{{transaction.location}}</div>
  <div *ngIf="username" id="transaction-user">{{username}}</div>

  <div id="transaction-date">
    <span>{{transaction.startdate | date:"dd.MM.yyy"}}&nbsp;-&nbsp;</span>
    <span *ngIf="transaction.enddate">{{transaction.enddate | date:"dd.MM.yyy"}}</span>
    <span *ngIf="!transaction.enddate">{{ 'general.now' | translate }}</span>
  </div>

  <form [formGroup]="scannerForm" (ngSubmit)="scanCode()">
    <mat-slide-toggle formControlName="isAddNew">{{ 'internal.inventory.isAddNew' | translate }}</mat-slide-toggle>

    <mat-form-field>
      <input #scannerInput matInput placeholder="{{ 'internal.inventory.scanner' | translate }}" formControlName="input" />
      <button type="button" mat-icon-button matSuffix (click)="openCameraScanner()"><mat-icon>camera_alt</mat-icon></button>
    </mat-form-field>
  </form>

  <div id="item-list">
    <div id="item-list-header">
      <div id="item-list-header-title">{{ 'internal.inventory.items' | translate }}</div>
    </div>

    <div id="hspacer"></div>

    <div id="item-list-content">
      <div *ngFor="let types of itemTypes" class="item" [ngClass]="{returned: itemComplete(types._id)}">
        <div class="item-name">{{types.name}}</div>
        <div class="instance-list">
          <div *ngFor="let instance of itemFilter(types._id)" class="instance" [ngClass]="{returned: instance.back, deleted: instanceDeleted(types._id, instance.instanceid), updating: instanceUpdating(types._id, instance.instanceid)}">
            <div class="instance-name">{{types.name}}</div>
            <div *ngIf="types.additionalInfo" class="instance-addInfo">{{types.additionalInfo}}</div>
            <div class="instance-id">{{instance.instanceid}}</div>
            <div class="instance-status">{{(instance.back? "internal.inventory.returned" : "internal.inventory.notReturned") | translate}}</div>
            <div *ngIf="instanceDeleted(types._id, instance.instanceid)" class="instance-status">{{ 'internal.inventory.deleted' | translate }}</div>
            <div class="instance-actions">
              <mat-spinner *ngIf="instanceUpdating(types._id, instance.instanceid)" [diameter]="18"></mat-spinner>
              <button mat-icon-button (click)="printLabel(types, instance)"><mat-icon>print</mat-icon></button>
              <button mat-icon-button (click)="selectItem(types, [instance.instanceid])"><mat-icon>visibility</mat-icon></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="transedit-actions">
  <button mat-stroked-button color="warn" (click)="cancelEdit()">{{ 'general.close' | translate }}</button>
</div>
