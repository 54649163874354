import { Component, ElementRef, Inject, ViewChild } from "@angular/core";
import { FormControl, FormGroup, UntypedFormBuilder } from "@angular/forms";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiService } from "../api.service";
import { BarcodescannerdialogComponent } from "../dialogs/barcodescannerdialog/barcodescannerdialog.component";
import { TAGInvItemInstance, TAGInvItemType, TAGInvTransaction } from "../models/taginventory";
import { ItemEditDialog } from "./itemEditDialog";
import { getInventoryInstanceByCode } from "./inventoryHelper";
import { Socket } from "ngx-socket-io";
import { PrinterService } from "../printer.service";

export interface TransactionEditDialogData {
  transactionId: string;
  socket: Socket;
}

@Component({
  selector: "int-inventory-transactionEditDialog",
  templateUrl: "transactionEditDialog.html",
  styleUrls: ["./transactionEditDialog.scss"],
})
export class TransactionEditDialog {
  //TODO: Form with validation

  @ViewChild("scannerInput") scannerInput: ElementRef | undefined;

  scannerForm: FormGroup = new FormGroup({
    isAddNew: new FormControl(false),
    input: new FormControl(""),
  });

  transaction: TAGInvTransaction = new TAGInvTransaction();
  itemTypes: TAGInvItemType[] = [];

  username: string = "";

  updatingItems: { itemid: string, instanceid: string }[] = [
    { itemid: "2b7c81ba30e286041341b78197003b82", instanceid: "1" },
  ];

  instanceDeleted(itemid: string, instanceid: string) {
    var item = this.itemTypes.find((itm) => itm._id == itemid);
    if (!item) return false;

    var instance = item.instances.find((inst) => inst.itemid == instanceid);
    if (!instance) return false;

    return item.deleted || instance.deleted;
  }

  instanceUpdating(itemid: string, instanceid: string) {
    return this.updatingItems.findIndex((itm) => itm.itemid == itemid && itm.instanceid == instanceid) != -1;
  }

  constructor(
    public api: ApiService,
    public dialog: MatDialog,
    private printer: PrinterService,
    public dialogRef: MatDialogRef<TransactionEditDialog>,
    @Inject(MAT_DIALOG_DATA) public data: TransactionEditDialogData
  ) {
    api.waitForAPI().then(() => {
      api.getInvTransaction(data.transactionId).then((transaction) => {
        this.transaction = transaction;
        this.updateValues();

        api.getUser(this.transaction.user).then((user) => {
          this.username = user.fullname;
        }).catch((reason) => {

        });

        this.initSocketHandler();
      }).catch((reason) => {
        console.error(reason);
      });
    });

    this.dialogRef.disableClose = true;
  }

  ngAfterViewInit() {
    this.resetScannerField();
  }

  ngOnDestroy(): void {
    this.data.socket.removeListener("updateTransaction", this.socket_onUpdateTransaction.bind(this));
  }

  initSocketHandler() {
    this.data.socket.on("updateTransaction", this.socket_onUpdateTransaction.bind(this));
  }

  socket_onUpdateTransaction(data: any) {
    let newTrans = new TAGInvTransaction(data.transaction);

    if (newTrans._id == this.transaction._id) {
      console.log("Transaction updated", newTrans);
      this.transaction = newTrans;
      this.updateValues();
    }
  }

  updateValues() {
    this.api.getInvItems().then((items) => {
      this.itemTypes = items.filter((item) => this.transaction.items.findIndex((itm) => itm.itemid == item._id) != -1);

      if (this.transaction.allItemsBack() && this.transaction.items.length > 0) {
        this.scannerForm.disable();
      } else {
        this.scannerForm.enable();
      }
    }).catch((reason) => {
      console.error(reason);
    });
  }

  cancelEdit(): void {
    console.log(this.scannerForm.value["isAddNew"]);
    this.dialogRef.close(false);
  }

  returnInstance(itemid: string, instanceid: string) {
    this.updatingItems.push({ itemid: itemid, instanceid: instanceid });

    this.api.returnTransactionItems(this.transaction._id, [{itemid: itemid, instanceid: instanceid}]).then((result) => {
      this.updatingItems = this.updatingItems.filter((itm) => itm.itemid != itemid && itm.instanceid != instanceid);
      this.transaction = result;
      this.updateValues();
    }).catch((reason) => {
      console.error(reason);
    });
  }

  addNewInstance(itemid: string, instanceid: string) {
    this.updatingItems.push({ itemid: itemid, instanceid: instanceid });
    this.updateValues();

    this.api.addTransactionItems(this.transaction._id, [{itemid: itemid, instanceid: instanceid}]).then((result) => {
      this.updatingItems = this.updatingItems.filter((itm) => itm.itemid != itemid && itm.instanceid != instanceid);
      this.transaction = result;
      this.updateValues();
    }).catch((reason) => {
      console.error(reason);
    });
  }

  selectItem(item: TAGInvItemType, selectedInstances?: string[]) {
    let width = "50%";
    if (window.innerWidth < 600) {
      width = "100%";
    }

    const dialogRef = this.dialog.open(ItemEditDialog, {
      minWidth: "300px",
      width: width,
      data: { item: item, selectedInstances: selectedInstances },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.resetScannerField();
    });
  }

  resetScannerField() {
    let isAddNew = this.scannerForm.get("isAddNew")?.value;
    this.scannerForm.reset();
    this.scannerInput?.nativeElement.focus();
    this.scannerForm.get("isAddNew")?.setValue(isAddNew);
  }

  scanCode() {
    var scannedID = this.scannerForm.get("input")?.value;
    if (scannedID != null) this.codeScanned(scannedID.trim());
  }

  openCameraScanner() {
    const dialogRef = this.dialog.open(BarcodescannerdialogComponent, {
      width: "500px",
      data: { multiple: true },
    });

    dialogRef.componentInstance.onCodeScan.subscribe((code: string) => {
      this.codeScanned(code);
      this.resetScannerField();
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
    });
  }

  codeScanned(code: string) {
    getInventoryInstanceByCode(code, this.api).then((result) => {
      let item = result.item;
      let instanceID = result.instanceID;

      console.log(result);

      if (item && instanceID) {
        this.itemScanned(item._id, instanceID);
      }
    }).catch((reason) => {
      console.log(reason);

      if (this.api.config.allowGroupsForBookIn) {
        this.api.getInvItemGroup(code).then((group) => {
          if (group) {
            for (let gItem of group.items) {
              this.itemScanned(gItem.itemid, gItem.instanceid);
            }
          }
        }).catch((reason) => {
          console.error(reason);
        });
      }
    }).finally(() => {
      this.resetScannerField();
    });
  }

  printLabel(item: TAGInvItemType, instance: TAGInvItemInstance) {
    if (!this.printer.isConnected())
      this.printer
        .connectToPrinter()
        .then((result) => {
          this.printer.printItemLabels(item, [instance]);
        })
        .catch((reason) => {
          console.error(reason);
        });
    else this.printer.printItemLabels(item, [instance]);
  }

  itemScanned(itemID: string, instID: string) {
    if (this.scannerForm.value["isAddNew"]) {
      var index: number = this.transaction.items.findIndex((comb) => comb.itemid == itemID && comb.instanceid == instID);
      if (index == -1 || this.transaction.items[index].back != null) {
        this.addNewInstance(itemID, instID);
      }
    } else {
      var index: number = this.transaction.items.findIndex((comb) => comb.itemid == itemID && comb.instanceid == instID);
      if (index != -1 && this.transaction.items[index].back == null) {
        this.returnInstance(itemID, instID);
      }
    }
  }

  itemFilter(itemid: string) {
    return this.transaction.items.filter((comb) => comb.itemid == itemid);
  }

  itemComplete(itemid: string) {
    return this.itemFilter(itemid).filter((comb) => comb.back == null).length == 0;
  }
}
