<div class="transaction" [ngClass]="{notdone: !transaction.allItemsBack() || transaction.items.length == 0}">
    <div class="transaction-location">{{transaction.location}}</div>
    <div class="transaction-startdate">{{transaction.startdate | date:"dd.MM.yyy"}}&nbsp;-&nbsp;</div>
    <div *ngIf="transaction.enddate" class="transaction-enddate">{{transaction.enddate | date:"dd.MM.yyy"}}</div>
    <div *ngIf="!transaction.enddate" class="transaction-enddate">{{ 'general.now' | translate }}</div>

    <div class="transaction-actions">
      <button *ngIf="showPrintButton" mat-icon-button (click)="printTransactionChecklist(transaction)"><mat-icon>receipt_long</mat-icon></button>
      <button *ngIf="showViewButton" mat-icon-button (click)="showTransaction(transaction)"><mat-icon>visibility</mat-icon></button>
      <button *ngIf="showSelectButton" mat-icon-button (click)="selectTransaction(transaction)"><mat-icon>arrow_forward</mat-icon></button>
      <button *ngIf="showUnlinkButton" mat-icon-button (click)="unlinkTransaction()"><mat-icon>attach_file_off</mat-icon></button>
    </div>
</div>