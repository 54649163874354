import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ApiService } from "../api.service";
import { BarcodescannerdialogComponent } from "../dialogs/barcodescannerdialog/barcodescannerdialog.component";
import { openDeleteDialog } from "../dialogs/deleteDialog/deleteDialog";
import { Permission } from "../models/tagpermission";
import { TAGInvItemGroup, TAGInvItemType, TAGInvTransaction } from "../models/taginventory";
import { PrinterService } from "../printer.service";
import { GroupEditDialog } from "./groupEditDialog";
import { GroupListDialog } from "./groupListDialog";
import { InventoryCheckDialog } from "./inventoryCheckDialog";
import { TransactionCreateDialog } from "./transactionCreateDialog";
import { ItemEditDialog } from "./itemEditDialog";
import { MissingItemsDialog } from "./missingItemsDialog";
import { TransactionEditDialog } from "./transactionEditDialog";
import { TransactionListDialog } from "./transactionListDialog";
import { getLocalInventoryInstanceByCode } from "./inventoryHelper";
import { Socket } from "ngx-socket-io";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-internal.inventory",
  templateUrl: "./internal.inventory.component.html",
  styleUrls: ["./internal.inventory.component.scss"],
})
export class InternalInventoryComponent implements OnInit {
  @ViewChild("scannerInput") scannerInput: ElementRef | undefined;

  categories: string[] = [];
  items: TAGInvItemType[] = [];
  groups: TAGInvItemGroup[] = [];

  selectedCategory: string = undefined;
  searchTerm: string = "";

  selectedItems: string[] = [];

  scannerForm = this.fb.group({
    input: [""],
  });


  mobileDialogSettings: any = {
    position: { top: "0%" },
    maxHeight: "90vh",
  };

  constructor(private api: ApiService, private socket: Socket, private fb: UntypedFormBuilder, private printer: PrinterService, public dialog: MatDialog, public snackbar: MatSnackBar) {}

  hasPerm(permission: string): boolean {
    return this.api.hasPermission(Permission.fromName(permission));
  }

  printerSupported(): boolean {
    return this.printer.isSupported();
  }

  printerConnected(): boolean {
    return this.printer.isConnected();
  }

  printerPrinting(): boolean {
    return this.printer.isPrintingLabels();
  }

  connectPrinter() {
    this.printer.connectToPrinter();
  }

  isOnMobile() {
    var ua = navigator.userAgent;

    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua);
  }

  ngOnInit(): void {
    this.api.waitForAPI().then(() => {
      this.loadItems();

      this.socket.on("loginInv", (data) => {
        if (!data.success) {
          console.error("Failed to login to inventory socket: " + data.message);
        } else {
          this.initSocketEvents();
        }
      });

      this.socket.emit("loginInv", {token: this.api.token});
    });
  }

  ngOnDestroy(): void {
    this.socket.removeListener("createItem", this.socket_onCreateItem.bind(this));
    this.socket.removeListener("updateItem", this.socket_onUpdateItem.bind(this));
    this.socket.removeListener("deleteItem", this.socket_onDeleteItem.bind(this));
  }

  initSocketEvents() {
    this.socket.on("createItem", this.socket_onCreateItem.bind(this));
    this.socket.on("updateItem", this.socket_onUpdateItem.bind(this));
    this.socket.on("deleteItem", this.socket_onDeleteItem.bind(this));
  }

  socket_onCreateItem(data: any) {
    this.items.push(new TAGInvItemType(data.item));
    this.extractCategories();
  }

  socket_onUpdateItem(data: any) {
    this.items.splice(this.items.findIndex((item) => item._id == data.item._id), 1, new TAGInvItemType(data.item));
    this.extractCategories();
  }

  socket_onDeleteItem(data: any) {
    this.items = this.items.filter((item) => item._id != data.item._id);
    this.extractCategories();
  }

  loadItems() {
    this.api.getInvItems().then((result) => {
      this.items = result;
      this.extractCategories();
    }).catch((reason) => {
      console.error(reason);
      this.snackbar.open(reason.message, "OK", { duration: 5000 });
    });

    this.api.getInvItemGroups().then((result) => {
      this.groups = result;
    }).catch((reason) => {
      console.error(reason);
      this.snackbar.open(reason.message, "OK", { duration: 5000 });
    });
  }

  filteredItems(): TAGInvItemType[] {
    if (this.searchTerm != "") {
      this.selectedCategory = undefined;
    }

    var items = this.items.filter((item) => (item.category == this.selectedCategory || this.selectedCategory == undefined) && (this.searchTerm == "" || item.name.toLowerCase().includes(this.searchTerm.toLowerCase())));
    return items.filter((item) => !item.deleted);
  }

  countCategory(category: string): number {
    return this.filteredItems().filter((item) => item.category == category).length;
  }

  ngAfterViewInit() {
    this.resetScannerField();
  }

  resetScannerField() {
    this.scannerForm.reset();
    this.scannerInput?.nativeElement.focus();
  }

  openCreateTransactionDialog() {
    const dialogRef = this.dialog.open(TransactionCreateDialog, {
      minWidth: "400px",
      maxWidth: "600px",
      width: "50%",
      data: { },
      ...this.isOnMobile() ? this.mobileDialogSettings : {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);

      this.api.createTransaction(result).then((transaction) => {
        this.showTransaction(transaction._id);
      }).catch((reason) => {
        console.error(reason);
        this.snackbar.open(reason.message, "OK", { duration: 5000 });
      });

      this.resetScannerField();
    });
  }

  showTransactions() {
    const dialogRef = this.dialog.open(TransactionListDialog, {
      minWidth: "400px",
      maxWidth: "600px",
      width: "50%",
      data: {
        socket: this.socket,
      },
      ...this.isOnMobile() ? this.mobileDialogSettings : {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);

      this.resetScannerField();
    });
  }

  showMissingItems() {
    const dialogRef = this.dialog.open(MissingItemsDialog, {
      minWidth: "400px",
      maxWidth: "600px",
      width: "50%",
      data: {},
      ...this.isOnMobile() ? this.mobileDialogSettings : {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);

      this.resetScannerField();
    });
  }

  showGroups() {
    const dialogRef = this.dialog.open(GroupListDialog, {
      minWidth: "400px",
      maxWidth: "600px",
      width: "50%",
      data: {},
      ...this.isOnMobile() ? this.mobileDialogSettings : {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);

      this.resetScannerField();
    });
  }

  showGroup(group: TAGInvItemGroup) {
    const dialogRef = this.dialog.open(GroupEditDialog, {
      minWidth: "400px",
      maxWidth: "600px",
      width: "50%",
      data: {group: group},
      ...this.isOnMobile() ? this.mobileDialogSettings : {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);

      this.resetScannerField();
    });
  }

  startInventoryCheck() {
    const dialogRef = this.dialog.open(InventoryCheckDialog, {
      minWidth: "400px",
      maxWidth: "600px",
      width: "50%",
      data: {},
      ...this.isOnMobile() ? this.mobileDialogSettings : {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result) {
        this.showTransaction(result._id);
      }
      this.resetScannerField();
    });
  }

  showTransaction(transactionId: string) {
    const dialogRef = this.dialog.open(TransactionEditDialog, {
      minWidth: "400px",
      width: "50%",
      data: { transactionId: transactionId, socket: this.socket },
      ...this.isOnMobile() ? this.mobileDialogSettings : {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      this.resetScannerField();
    });
  }

  extractCategories() {
    this.categories = [];
    this.items
      .filter((item) => !item.deleted)
      .forEach((item) => {
        if (!this.categories.includes(item.category)) {
          this.categories.push(item.category);
        }
      });
    this.categories.sort();

    if (!this.categories.includes(this.selectedCategory)) {
      //this.selectCategory(this.categories[0]);
      this.selectCategory(undefined);
    }

    console.log(this.categories);
  }

  scanCode() {
    var scannedID = this.scannerForm.get("input")?.value;
    if (scannedID != null) this.codeScanned(scannedID.trim());
  }

  openCameraScanner() {
    const dialogRef = this.dialog.open(BarcodescannerdialogComponent, {
      width: "500px",
      data: {},
      ...this.isOnMobile() ? this.mobileDialogSettings : {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      if (result != null) {
        if (result.error) {
          console.log(result.error);
        } else {
          this.codeScanned(result.code);
        }
      }

      this.resetScannerField();
    });
  }

  codeScanned(code: string) {
    let {item, instanceID} = getLocalInventoryInstanceByCode(code, this.items);

    if (item) {
      let instances = item.instances.filter((inst) => inst.itemid == instanceID);
      this.selectItem(item, instances.map((inst) => inst.itemid));
    } else {
      let group = this.groups.find((group) => group._id == code);
      if (group) {
        this.showGroup(group);
      } else {
        console.log("Unknown code: " + code);
      }
    }

    this.scannerForm.reset();
    this.scannerInput?.nativeElement.focus();
  }

  selectCategory(category: string) {
    this.selectedCategory = category;
    this.selectedItems = [];
    this.resetScannerField();
  }

  selectItem(item: TAGInvItemType, selectedInstances?: string[]) {
    let width = "50%";
    if (window.innerWidth < 600) {
      width = "100%";
    }

    const dialogRef = this.dialog.open(ItemEditDialog, {
      minWidth: "300px",
      width: width,
      data: { item: item, selectedInstances: selectedInstances },
      ...this.isOnMobile() ? this.mobileDialogSettings : {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.delete) {
          this.items = this.items.filter((item) => item._id != result.item._id);
          this.extractCategories();
        } else {
          this.items.splice(this.items.indexOf(item), 1, result);
          this.extractCategories();
        }
      } else {
        console.log("Cancelled");
      }

      this.resetScannerField();
    });
  }

  newItem(bulk: boolean = false, lastCategory: string = undefined) {
    const dialogRef = this.dialog.open(ItemEditDialog, {
      minWidth: "400px",
      width: "50%",
      data: { item: new TAGInvItemType({ category: lastCategory?? this.selectedCategory }) },
      ...this.isOnMobile() ? this.mobileDialogSettings : {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.loadItems();
      if (bulk) {
        this.newItem(true, result.category);
      }

      this.resetScannerField();
    });
  }

  itemSelected(itemID: string, event: any) {
    if (event.checked) {
      this.selectedItems.push(itemID);
    } else {
      this.selectedItems = this.selectedItems.filter((id) => id != itemID);
    }

    console.log(this.selectedItems);
  }

  deleteSelected() {
    openDeleteDialog(
      this.dialog,
      "Items",
      this.selectedItems.map((id) => this.items.find((item) => item._id == id)?.name)
    )
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.selectedItems.forEach((item) => {
            this.api.deleteInvItem(item).then((result) => {
              this.items = this.items.filter((itm) => itm._id != item);
              this.selectedItems = this.selectedItems.filter((id) => id != item);
              this.extractCategories();
            }).catch((reason) => {
              console.error(reason);
              this.snackbar.open(reason.message, "OK", { duration: 5000 });
            });
          });
        }
      });
  }
}
