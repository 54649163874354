<div id="itemedit-header">
  {{item.name}}
  <span *ngIf="item.deleted"> {{ 'internal.inventory.itemEdit.itemDeleted' | translate }} </span>
  <div>{{item._id}}</div>
</div>

<div id="itemedit-form">
  <mat-form-field appearance="standard">
    <mat-label>{{ 'internal.inventory.itemEdit.name' | translate }}</mat-label>
    <input matInput placeholder="XLR Kabel" [disabled]="editingDisabled" [(ngModel)]="item.name" />
    <mat-icon matSuffix>edit</mat-icon>
  </mat-form-field>

  <mat-form-field appearance="standard">
    <mat-label>{{ 'internal.inventory.itemEdit.additionalInformation' | translate }}</mat-label>
    <input matInput placeholder="3m" [disabled]="editingDisabled" [(ngModel)]="item.additionalInfo" />
    <mat-icon matSuffix>edit</mat-icon>
  </mat-form-field>

  <mat-form-field class="desktop" appearance="standard">
    <mat-label>{{ 'internal.inventory.itemEdit.description' | translate }}</mat-label>
    <textarea matInput placeholder="3M XLR Kabel" [disabled]="editingDisabled" [(ngModel)]="item.description"></textarea>
    <mat-icon matSuffix>edit</mat-icon>
  </mat-form-field>

  <mat-form-field appearance="standard">
    <mat-label>{{ 'internal.inventory.itemEdit.category' | translate }}</mat-label>
    <input matInput placeholder="XLR Kabel" [disabled]="editingDisabled" [(ngModel)]="item.category" [formControl]="catControl" [matAutocomplete]="categoryAutocomplete" />
    <mat-icon matSuffix>edit</mat-icon>

    <mat-autocomplete #categoryAutocomplete="matAutocomplete">
      <mat-option *ngFor="let category of filteredCategories | async" [value]="category"> {{category}} </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <div id="instance-list">
    <div id="instance-list-header">
      <div id="instance-list-header-title">
        <mat-checkbox [checked]="allItemsSelected()" (change)="selectAll()"></mat-checkbox>
        {{ 'internal.inventory.instances' | translate }}
      </div>

      <div id="instance-list-header-actions">
        <button mat-stroked-button class="desktop" (click)="printLabelsAll()" [disabled]="selectedInstances.length <= 0 || !item._id">{{ 'internal.inventory.printLabels' | translate }}</button>
        <button mat-stroked-button class="desktop" (click)="addInstance()" [disabled]="editingDisabled">{{ 'internal.inventory.itemEdit.add' | translate }}</button>
        <button mat-icon-button class="mobile" (click)="printLabelsAll()" [disabled]="selectedInstances.length <= 0 || !item._id"><mat-icon>print</mat-icon></button>
        <button mat-icon-button class="mobile" (click)="addInstance()" [disabled]="editingDisabled"><mat-icon>add</mat-icon></button>
      </div>
    </div>

    <div id="hspacer"></div>

    <div id="instance-list-content">
      <div *ngFor="let instance of filteredInstances(showDeletedInstances)" class="instance">
        <mat-checkbox [checked]="selectedInstances.includes(instance.itemid)" (change)="instanceSelected(instance.itemid, $event.checked)"></mat-checkbox>
        <div class="instance-name" [ngClass]="{inuse: !instanceStatus(instance.itemid)}">{{item.name}}</div>
        <div class="instance-addInfo">{{item.additionalInfo}}</div>
        <div class="instance-id">{{instance.itemid}}</div>
        <div class="instance-status">{{(instance.deleted? "internal.inventory.deleted" : (instanceStatus(instance.itemid)? "" : "internal.inventory.inUse")) | translate}}</div>
        <div class="instance-barcode">
            {{instance.barcodes.length}} Barcodes
        </div>
        <div class="instance-actions">
          <button *ngIf="!instanceStatus(instance.itemid)" mat-icon-button (click)="showTransaction(instance)"><mat-icon>visibility</mat-icon></button>
          <button mat-icon-button (click)="editInstanceBarcodes(instance)"><mat-icon>qr_code_2_add</mat-icon></button>
          <button mat-icon-button [disabled]="deletionDisabled || !instanceStatus(instance.itemid)" (click)="deleteInstance(instance.itemid)"><mat-icon>{{instance.deleted? 'delete_forever' : 'delete'}}</mat-icon></button>
        </div>
      </div>
    </div>
    <div id="hspacer"></div>
    <mat-checkbox style="padding: 8px" [(ngModel)]="showDeletedInstances">{{ 'internal.inventory.itemEdit.showDeletedInstances' | translate }}</mat-checkbox>
  </div>
</div>

<div id="itemedit-actions">
  <button *ngIf="item._id" mat-stroked-button [disabled]="deletionDisabled" color="warn" (click)="deleteItem()">{{ 'general.delete' | translate }}</button>
  <button mat-stroked-button color="warn" (click)="cancelEdit()">{{ 'general.cancel' | translate }}</button>
  <button mat-stroked-button [disabled]="editingDisabled" (click)="saveEdit(false)">{{ 'general.save' | translate }}</button>
  <button mat-stroked-button [disabled]="editingDisabled" (click)="saveEdit()">{{ 'general.saveAndExit' | translate }}</button>
</div>
