<div id="translist-header">
  <div id="translist-header-title">{{ 'internal.inventory.transactionList.title' | translate }}</div>
</div>

<div id="translist-form">
  <form [formGroup]="scannerForm" (ngSubmit)="scanCode()">
    <mat-form-field>
      <input
        #scannerInput
        matInput
        placeholder="{{ 'internal.inventory.scanner' | translate }}"
        formControlName="input"
        (change)="codeScanned(scannerInput.value)" />
      <button type="button" mat-icon-button matSuffix (click)="openCameraScanner()"><mat-icon>camera_alt</mat-icon></button>
    </mat-form-field>
  </form>

  <div id="transaction-list">
    <inv-transaction-list-item *ngFor="let trans of filteredTransactions()" [transaction]="trans" (changed)="loadTransactions()" (view)="openTransaction($event)"></inv-transaction-list-item>

    <empty-state
      *ngIf="filteredTransactions().length == 0"
      [icon]="'search_off'"
      [title]="'internal.inventory.transactionList.empty.title' | translate"
      [subtitle]="'internal.inventory.transactionList.empty.subtitle' | translate"
      [showButton]="true"
      [buttonText]="'internal.inventory.transactionList.empty.button' | translate"
      (buttonClick)="clearFilters()">
    </empty-state>
  </div>
</div>

<div id="translist-actions">
  <button mat-stroked-button (click)="cancel()">{{ 'general.close' | translate }}</button>
</div>
