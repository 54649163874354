import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TAGMusicWish } from "src/app/models/tagmusicwish";

export interface MusicWishSimilarDialogData {
  wish: TAGMusicWish;
  similar: TAGMusicWish;
}

@Component({
  selector: "musicWishSimilarDialog",
  templateUrl: "musicWishSimilarDialog.html",
  styleUrls: ["./musicWishSimilarDialog.scss"],
})
export class MusicWishSimilarDialog {
  constructor(public dialogRef: MatDialogRef<MusicWishSimilarDialog>, @Inject(MAT_DIALOG_DATA) public data: MusicWishSimilarDialogData) {}
}
