<div id="int-content-topbar">
  <internal-menu-button id="menuBtn"></internal-menu-button>
  <button *ngIf="hasPerm('delete_musicwishes') && wishesEnabled()" mat-stroked-button class="deleteallbtn" [disabled]="musicwishes.length == 0" (click)="this.deleteAllMusicwishes()">{{ "internal.musicwishes.deleteAll" | translate }}</button>
  <mat-slide-toggle style="margin: auto 0 auto auto;" (change)="toggleAutoReload($event)"></mat-slide-toggle>
  <button mat-icon-button (click)="reloadMusicWishes()">
    <mat-icon>refresh</mat-icon>
  </button>
</div>
<div id="int-musicwishes-content">
  <div *ngIf="wishesEnabled()">
    <mat-form-field>
      <input matInput (keyup)="applyFilter($event.target.value)" placeholder="{{ 'general.search' | translate }}" />
    </mat-form-field>

    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container class="column-wide" matColumnDef="owner">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.musicwishes.username" | translate }}</th>
          <td mat-cell *matCellDef="let row" (click)="this.copyMusicWish(row)">{{ row.owner }}</td>
        </ng-container>

        <ng-container class="column-wide" matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.musicwishes.title" | translate }}</th>
          <td mat-cell *matCellDef="let row" (click)="this.copyMusicWish(row)">{{ row.title }}</td>
        </ng-container>

        <ng-container class="column-wide" matColumnDef="artist">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.musicwishes.artist" | translate }}</th>
          <td mat-cell *matCellDef="let row" (click)="this.copyMusicWish(row)">{{ row.artist }}</td>
        </ng-container>

        <ng-container class="column-wide" matColumnDef="votes">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.musicwishes.votes" | translate }}</th>
          <td mat-cell *matCellDef="let row" (click)="this.copyMusicWish(row)">{{ row.votes }}</td>
        </ng-container>

        <ng-container class="column-wide" matColumnDef="played">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ "internal.musicwishes.played" | translate }}</th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button [style.color]="row.played? '#4caf50' : ''" (click)="toggleMusicWishPlayed(row)" [disabled]="!hasPerm('edit_musicwishes')">
              <mat-icon>{{row.played? 'play_arrow' : 'play_disabled'}}</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container class="column-wide" matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef>{{ "internal.musicwishes.delete" | translate }}</th>
          <td mat-cell *matCellDef="let row">
            <button mat-icon-button class="deletebtn" (click)="this.deleteMusicWish(row)" [disabled]="!hasPerm('delete_musicwishes')">
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>

      <mat-paginator [length]="musicwishes.length" [pageSize]="pageSize" (page)="switchPage($event)" [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>
  </div>
  <div *ngIf="!wishesEnabled()" id="wishdisabledmessage">
    {{ "internal.musicwishes.disabled" | translate }}
  </div>
</div>