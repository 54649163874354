import { Injectable } from "@angular/core";
import { HttpClient, HttpRequest, HttpEventType, HttpResponse } from "@angular/common/http";
import { CookieService } from "ngx-cookie-service";
import { Permission } from "./models/tagpermission";
import { TAGMusicWish } from "./models/tagmusicwish";
import { TAGRequest } from "./models/tagrequest";
import { TAGEvent } from "./models/tagevent";
import { TAGImageCategory } from "./models/tagimagecategory";
import { TAGImage } from "./models/tagimage";
import { TAGUser } from "./models/taguser";
import { TAGConfig } from "./models/tagconfig";
import { environment } from "src/environments/environment";
import { TAGPresence } from "./models/tagpresence";
import { Priority, TAGTodo, TodoStatus } from "./models/tagtodo";
import { TAGMail, TAGMailContact, TAGMailFolder } from "./models/tagmail";
import { OAuthAuthorizationRequest } from "./oauth/login/login.component";
import { TAGInvItemGroup, TAGInvItemType, TAGInvTransaction } from "./models/taginventory";
import { TAGTransaction } from "./models/tagfinances";
import { TAGLocation } from "./models/taglocation";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  //API_URL: string = "https://api.technikflg.com/v1/";
  //API_URL: string = "http://localhost:8080/v1/";
  API_URL: string = environment.apiUrl;

  API_SELECTOR_IMAGES: string = "images/";
  API_SELECTOR_IMAGE: string = "image/";
  API_SELECTOR_THUMBNAIL: string = "thumb/";
  API_SELECTOR_FULLIMAGE: string = "full/";
  API_SELECTOR_RANDOMIMAGE: string = "random/";
  API_SELECTOR_CATEGORIES: string = "categories/";
  API_SELECTOR_DASHBOARD: string = "dashboard/";
  API_SELECTOR_REQUESTS: string = "requests/";
  API_SELECTOR_TODOS: string = "todos/";
  API_SELECTOR_PRESENCE: string = "presence/";
  API_SELECTOR_EVENTS: string = "events/";
  API_SELECTOR_LOCATIONS: string = "locations/";
  API_SELECTOR_USERS: string = "users/";
  API_SELECTOR_MUSICWISHES: string = "musicwishes/";
  API_SELECTOR_SCHULRADIO: string = "schulradio/";
  API_SELECTOR_VOTE: string = "vote/";

  API_SELECTOR_CONFIG: string = "config/";

  API_SELECTOR_USER: string = "user/";
  API_SELECTOR_TOKEN: string = "token/";

  API_SELECTOR_FINANCES: string = "finances/";

  API_SELECTOR_INVENTORY: string = "inventory/";
  API_SELECTOR_ITEMS: string = "items/";
  API_SELECTOR_GROUPS: string = "groups/";
  API_SELECTOR_TRANSACTIONS: string = "transactions/";

  token: string = "";
  user: TAGUser = null;
  config: TAGConfig = new TAGConfig();

  readyListeners: (() => void)[] = [];

  onLogin: Promise<void> = null;

  constructor(public http: HttpClient, private cookieService: CookieService) {
    this.onLogin = new Promise<void>(async (resolve, reject) => {
      await this.getConfig();

      if (cookieService.check("token")) {
        this.token = cookieService.get("token");
      }

      this.getUserInfo().then((result) => {
        resolve();
      }).catch((reason) => {
        this.cookieService.deleteAll();
        this.token = "";
        this.user = null;
        resolve();
      });
    }).then(() => {
      this.onLogin = null;
    });
  }

  waitForAPI(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.onLogin != null) {
        this.onLogin.then(() => {
          return resolve();
        });
      } else {
        return resolve();
      }
    }).then(() => {
      this.onLogin = null;
    });
  }

  isLoggedIn() {
    return this.user != null && this.token != "";
  }

  getUsername() {
    if (this.user == null) return "ERROR";
    return this.user.username;
  }

  getFullname() {
    if (this.user == null) return "ERROR";
    return this.user.firstname + " " + this.user.lastname;
  }

  hasPermission(permission: Permission): boolean {
    return this.user.hasPermission(permission);
  }


  //#region General Stuff

  login(username: string, password: string) {
    return new Promise<TAGUser>((resolve, reject) => {
      this.http.post(this.API_URL + "user/login", { username: username, password: password }).subscribe((data: any) => {
        if (data.error == 0) {
          var user: TAGUser = new TAGUser(data.user);

          this.token = data.token;
          this.user = user;

          this.cookieService.set("username", username);
          this.cookieService.set("token", this.token);

          resolve(user);
        } else {
          reject(data);
        }
      });
    });
  }

  pinLogin(pin: string) {
    return new Promise<TAGUser>((resolve, reject) => {
      this.http.post(this.API_URL + "user/pinlogin", { pin: pin }).subscribe((data: any) => {
        if (data.error == 0) {
          var user: TAGUser = new TAGUser(data.user);

          this.token = data.token;
          this.user = user;

          this.cookieService.set("username", user.username);
          this.cookieService.set("token", this.token);

          resolve(user);
        } else {
          reject(data);
        }
      });
    });
  }

  logout() {
    this.token = "";
    this.user = null;

    this.cookieService.deleteAll();
    this.cookieService.delete("username");
    this.cookieService.delete('username', '/');
    this.cookieService.delete('username', '/', 'localhost');
    this.cookieService.delete('username', '/', 'https://technikflg.com');
    this.cookieService.delete('username', '/internal');
    this.cookieService.delete('username', '/internal', 'localhost');
    this.cookieService.delete('username', '/internal', 'https://technikflg.com');

    this.cookieService.delete("token");
    this.cookieService.delete('token', '/');
    this.cookieService.delete('token', '/', 'localhost');
    this.cookieService.delete('token', '/', 'https://technikflg.com');
    this.cookieService.delete('token', '/internal');
    this.cookieService.delete('token', '/internal', 'localhost');
    this.cookieService.delete('token', '/internal', 'https://technikflg.com');
  }

  getDocumentByUrl(url: string) {
    return new Promise<Blob>((resolve, reject) => {
      this.http.get(url, { responseType: "blob" }).subscribe((data: Blob) => {
        resolve(data);
      });
    });
  }

  /*   getDashboardInfo(success: (data) => void, error: (error) => void) {
    if (!this.isLoggedIn()) return null;

    this.http.get(this.API_URL + this.API_SELECTOR_DASHBOARD).subscribe((data: any) => {
      if (data.error == 0) {
        var response: any = {};

        response.registeredUsers = data.dashboard.registered_users;

        var events : TEEvent[] = [];
        data.dashboard.events.forEach(evt => {
          events.push(new TEEvent(evt));
        });
        response.events = events;

        var requests : TERequest[] = [];
        data.dashboard.requests.forEach(req => {
          requests.push(new TERequest(req));
        });
        response.requests = requests;

        success(response);
      } else {
        error(data);
      }
    });
  } */

  //#endregion

  //#region Config

  getConfig() {
    return new Promise<void>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_CONFIG).subscribe((data: any) => {
        if (data.error == 0) {
          this.config = new TAGConfig(data);
          resolve();
        } else {
          reject();
        }
      });
    });
  }

  saveConfig() {
    return new Promise<void>((resolve, reject) => {
      this.http.put(this.API_URL + this.API_SELECTOR_CONFIG, { config: this.config.serialize() }).subscribe((data: any) => {
        if (data.errpr == 0) {
          this.config = new TAGConfig(data.config);
          resolve();
        }
        else reject();
      });
    });
  }

  //#endregion

  //#region Users

  getUserInfo() {
    return new Promise<TAGUser>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_USER).subscribe((data: any) => {
        if (data.error == 0) {
          var user: TAGUser = new TAGUser(data.user);
          this.user = user;
          resolve(user);
        } else {
          reject(data);
        }
      });
    });
  }

  getUserMailInfo() {
    return new Promise<{email: string, password: string}>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_USER + "email").subscribe((data: any) => {
        if (data.error == 0) {
          resolve(data);
        } else {
          reject(data);
        }
      });
    });
  }

  getUsers() {
    return new Promise<TAGUser[]>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.get(this.API_URL + this.API_SELECTOR_USERS).subscribe((data: any) => {
        if (data.error == 0) {
          var users: TAGUser[] = [];
          data.users.forEach((usr) => {
            users.push(new TAGUser(usr));
          });
          resolve(users);
        } else {
          reject(data);
        }
      });
    });
  }

  getUser(userid: string) {
    return new Promise<TAGUser>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.get(this.API_URL + this.API_SELECTOR_USERS + userid).subscribe((data: any) => {
        if (data.error == 0) {
          var user: TAGUser = new TAGUser(data.user);
          resolve(user);
        } else {
          reject(data);
        }
      });
    });
  }

  updateUser(user: TAGUser) {
    return new Promise<string>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.put(this.API_URL + this.API_SELECTOR_USERS + user._id, { user: user.serialize() }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(data.userid);
        } else {
          reject(data);
        }
      });
    });
  }

  deleteUser(userid: string) {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.delete(this.API_URL + this.API_SELECTOR_USERS + userid).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  newUser(user: TAGUser) {
    return new Promise<string>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.post(this.API_URL + this.API_SELECTOR_USERS, { user: user.serialize() }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(data.userid);
        } else {
          reject(data);
        }
      });
    });
  }

  //#endregion

  //#region Presence

  getPresence(id: string) {
    return new Promise<TAGPresence>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.get(this.API_URL + this.API_SELECTOR_PRESENCE + id).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGPresence(data.presence));
        } else {
          reject(data);
        }
      });
    });
  }

  getPresences() {
    return new Promise<TAGPresence[]>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_PRESENCE).subscribe((data: any) => {
        if (data.error == 0) {
          let presence: TAGPresence[] = data.presence.map((pres) => {
            return new TAGPresence(pres);
          });
          resolve(presence);
        } else {
          reject(data);
        }
      });
    });
  }

  newPresence(presence: TAGPresence) {
    return new Promise<string>((resolve, reject) => {
      this.http.post(this.API_URL + this.API_SELECTOR_PRESENCE, { presence: presence.serialize() }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(data.presenceid);
        } else {
          reject(data);
        }
      });
    });
  }

  updatePresence(presence: TAGPresence, success: (data) => void, error: (error) => void) {
    return new Promise<string>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.put(this.API_URL + this.API_SELECTOR_PRESENCE + presence._id, { presence: presence.serialize() }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(data.presenceid);
        } else {
          reject(data);
        }
      });
    });
  }

  deletePresence(id: string, success: (data) => void, error: (error) => void) {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.delete(this.API_URL + this.API_SELECTOR_PRESENCE + id).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  //#endregion

  //#region Todos

  getTodos() {
    return new Promise<TAGTodo[]>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_TODOS).subscribe((data: any) => {
        if (data.error == 0) {
          let todos: TAGTodo[] = data.todos.map((todo) => {
            return new TAGTodo(todo);
          });
          resolve(todos);
        } else {
          reject(data);
        }
      });
    });
  }

  getTodo(todoid: string) {
    return new Promise<TAGTodo>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.get(this.API_URL + this.API_SELECTOR_TODOS + todoid).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGTodo(data.todo));
        } else {
          reject(data);
        }
      });
    });
  }

  getTodosByStatus(status: TodoStatus) {
    return new Promise<TAGTodo[]>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.get(this.API_URL + this.API_SELECTOR_TODOS + "status/" + status.name).subscribe((data: any) => {
        if (data.error == 0) {
          let todos: TAGTodo[] = data.todos.map((todo) => {
            return new TAGTodo(todo);
          });
          resolve(todos);
        } else {
          reject(data);
        }
      });
    });
  }

  getTodosByPriority(priority: Priority) {
    return new Promise<TAGTodo[]>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.get(this.API_URL + this.API_SELECTOR_TODOS + "priority/" + priority.name).subscribe((data: any) => {
        if (data.error == 0) {
          let todos: TAGTodo[] = data.todos.map((todo) => {
            return new TAGTodo(todo);
          });
          resolve(todos);
        } else {
          reject(data);
        }
      });
    });
  }

  getTodosByAssignee(assignee: string) {
    return new Promise<TAGTodo[]>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.get(this.API_URL + this.API_SELECTOR_TODOS + "assignee/" + assignee).subscribe((data: any) => {
        if (data.error == 0) {
          let todos: TAGTodo[] = data.todos.map((todo) => {
            return new TAGTodo(todo);
          });
          resolve(todos);
        } else {
          reject(data);
        }
      });
    });
  }

  newTodo(todo: TAGTodo) {
    return new Promise<string>((resolve, reject) => {
      this.http.post(this.API_URL + this.API_SELECTOR_TODOS, { todo: todo.serialize() }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(data.todoid);
        } else {
          reject(data);
        }
      });
    });
  }

  updateTodo(todo: TAGTodo) {
    return new Promise<string>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.put(this.API_URL + this.API_SELECTOR_TODOS + todo._id, { todo: todo.serialize() }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(data.todoid);
        } else {
          reject(data);
        }
      });
    });
  }

  deleteTodo(todoid: string) {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.delete(this.API_URL + this.API_SELECTOR_TODOS + todoid).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  //#endregion

  //#region Requests

  getRequest(requestid: string) {
    return new Promise<TAGRequest>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.get(this.API_URL + this.API_SELECTOR_REQUESTS + requestid).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGRequest(data.request));
        } else {
          reject(data);
        }
      });
    });
  }

  getRequests() {
    return new Promise<TAGRequest[]>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_REQUESTS).subscribe((data: any) => {
        if (data.error == 0) {
          let requests: TAGRequest[] = data.requests.map((req) => {
            return new TAGRequest(req);
          });
          resolve(requests);
        } else {
          reject(data);
        }
      });
    });
  }

  newRequest(request: TAGRequest) {
    return new Promise<string>((resolve, reject) => {
      this.http.post(this.API_URL + this.API_SELECTOR_REQUESTS, { request: request.serialize() }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(data.requestid);
        } else {
          reject(data);
        }
      });
    });
  }

  updateRequest(request: TAGRequest) {
    return new Promise<string>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.put(this.API_URL + this.API_SELECTOR_REQUESTS + request._id, { request: request.serialize() }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(data.requestid);
        } else {
          reject(data);
        }
      });
    });
  }

  deleteRequest(requestid: string) {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.delete(this.API_URL + this.API_SELECTOR_REQUESTS + requestid).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  promoteRequest(requestid: string) {
    return new Promise<string>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.put(this.API_URL + this.API_SELECTOR_REQUESTS + "promote/" + requestid, {}).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(data.eventid);
        } else {
          reject(data);
        }
      });
    });
  }

  //#endregion

  //#region Events

  getEvent(eventid: string) {
    return new Promise<TAGEvent>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.get(this.API_URL + this.API_SELECTOR_EVENTS + eventid).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGEvent(data.event));
        } else {
          reject(data);
        }
      });
    });
  }

  getEvents(onlypublic: boolean = false) {
    return new Promise<TAGEvent[]>((resolve, reject) => {
      if (!this.isLoggedIn() && !onlypublic) reject();

      this.http.get(this.API_URL + this.API_SELECTOR_EVENTS + (onlypublic ? "public" : "")).subscribe((data: any) => {
        if (data.error == 0) {
          let events: TAGEvent[] = data.events.map((evt) => {
            return new TAGEvent(evt);
          });
          resolve(events);
        } else {
          reject(data);
        }
      });
    });
  }

  updateEvent(event: TAGEvent) {
    return new Promise<TAGEvent>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.put(this.API_URL + this.API_SELECTOR_EVENTS + event._id, { event: event.serialize() }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGEvent(data.event));
        } else {
          reject(data);
        }
      });
    });
  }

  deleteEvent(eventid: string) {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.delete(this.API_URL + this.API_SELECTOR_EVENTS + eventid).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  newEvent(event: TAGEvent) {
    return new Promise<TAGEvent>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.post(this.API_URL + this.API_SELECTOR_EVENTS, { event: event.serialize() }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGEvent(data.event));
        } else {
          reject(data);
        }
      });
    });
  }

  getAllEventAttachments(eventID: string) {
    return new Promise<{fileName: string, mimeType: string}[]>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_EVENTS + eventID + "/file").subscribe((data: any) => {
        if (data.error == 0) {
          resolve(data.files);
        } else {
          reject(data);
        }
      });
    });
  }

  getEventAttachment(eventID: string, fileName: string) {
    return new Promise<any>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_EVENTS + eventID + "/file/" + fileName, { responseType: "blob" }).subscribe((data: any) => {
        resolve(data);
      });
    });
  }

  uploadEventAttachment(eventID: string, fileName: string, fileData: string, progress: (progress) => void) {
    return new Promise<TAGEvent>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      const options = {
        reportProgress: true,
        observe: "events",
      };

      const req = new HttpRequest("POST", this.API_URL + this.API_SELECTOR_EVENTS + eventID + "/file/" , { fileName: fileName, file: fileData }, options);

      this.http.request(req).subscribe(
        (event) => {
          console.log("progress: ");
          console.log(event);
          if (event.type == HttpEventType.UploadProgress) {
            const percentDone = Math.round((100 * event.loaded) / event.total);
            console.log(`File is ${percentDone}% loaded.`);
            progress(percentDone);
          } else if (event instanceof HttpResponse) {
            console.log("File is completely loaded!");
            console.log(event.body as any);
            if ((event.body as any).error == 0) {
              resolve(new TAGEvent((event as HttpResponse<any>).body.event));
            } else {
              reject(event.body);
            }
          }
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  deleteEventAttachment(eventID: string, fileName: string) {
    return new Promise<TAGEvent>((resolve, reject) => {
      this.http.delete(this.API_URL + this.API_SELECTOR_EVENTS + eventID + "/file/" + fileName).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGEvent(data.event));
        } else {
          reject(data);
        }
      });
    });
  }

  //#endregion

  //#region MusicWishes

  getMusicWishes() {
    return new Promise<TAGMusicWish[]>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_MUSICWISHES).subscribe((data: any) => {
        if (data.error == 0) {
          let wishes: TAGMusicWish[] = data.musicwishes.map((wish) => {
            return new TAGMusicWish(wish);
          });

          resolve(wishes);
        } else {
          reject(data);
        }
      });
    });
  }

  newMusicWishes(wish: TAGMusicWish) {
    return new Promise<string>((resolve, reject) => {
      this.http.post(this.API_URL + this.API_SELECTOR_MUSICWISHES, { wish: wish.serialize() }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(data.wishid);
        } else {
          reject(data);
        }
      });
    });
  }

  updateMusicWish(wish: Partial<TAGMusicWish>) {
    return new Promise<TAGMusicWish>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.put(this.API_URL + this.API_SELECTOR_MUSICWISHES + wish._id, { wish: wish.serialize() }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGMusicWish(data.wish));
        } else {
          reject(data);
        }
      });
    });
  }

  voteForMusicWish(wishid: string) {
    return new Promise<void>((resolve, reject) => {
      this.http.post(this.API_URL + this.API_SELECTOR_MUSICWISHES + this.API_SELECTOR_VOTE + wishid, {}).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  deleteMusicWishes(wishid: string) {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.delete(this.API_URL + this.API_SELECTOR_MUSICWISHES + wishid).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  deleteAllMusicWishes() {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.delete(this.API_URL + this.API_SELECTOR_MUSICWISHES).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  //#endregion

  //#region Categories

  getCategories() {
    return new Promise<TAGImageCategory[]>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_CATEGORIES).subscribe((data: any) => {
        if (data.error == 0) {
          let categories: TAGImageCategory[] = data.categories.map((cat) => {
            return new TAGImageCategory(cat);
          });

          resolve(categories);
        } else {
          reject(data);
        }
      });
    });
  }

  updateCategory(category: TAGImageCategory) {
    return new Promise<string>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.put(this.API_URL + this.API_SELECTOR_CATEGORIES + category._id, { category: category.serialize() }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(data.categoryid);
        } else {
          reject(data);
        }
      });
    });
  }

  deleteCategory(categoryid: string) {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.delete(this.API_URL + this.API_SELECTOR_CATEGORIES + categoryid).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  newCategory(category: TAGImageCategory) {
    return new Promise<string>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.post(this.API_URL + this.API_SELECTOR_CATEGORIES, { category: category.serialize() }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(data.categoryid);
        } else {
          reject(data);
        }
      });
    });
  }

  getCategoryForEvent(eventid: string) {
    return new Promise<TAGImageCategory>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_CATEGORIES + this.API_SELECTOR_EVENTS + eventid).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGImageCategory(data.category));
        } else {
          reject(data);
        }
      });
    });
  }

  //#endregion

  //#region Images

  getImageFull(imageid: string) {
    return new Promise<string>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_IMAGE + imageid + "/" + this.API_SELECTOR_FULLIMAGE).subscribe((data: any) => {
        resolve("data:image/jpeg;base64," + data);
      });
    });
  }

  getImageThumb(imageid: string) {
    return new Promise<string>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_IMAGE + imageid + "/" + this.API_SELECTOR_THUMBNAIL).subscribe((data: any) => {
        //TODO WTH?
        console.log(data);
        resolve("data:image/jpeg;base64," + data);

        /* if (data.error == 0) {
          resolve(data.imageData);
        } else {
          reject(data);
        } */
      });
    });
  }

  getImages(categoryid: string) {
    return new Promise<TAGImage[]>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_IMAGES + categoryid).subscribe((data: any) => {
        if (data.error == 0) {
          let images: TAGImage[] = data.images.map((img) => {
            return new TAGImage(img);
          });

          resolve(images);
        } else {
          reject(data);
        }
      });
    });
  }

  deleteImage(imageid: string) {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.delete(this.API_URL + this.API_SELECTOR_IMAGE + imageid).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  newImage(image: TAGImage, imageFile: string, progress: (progress) => void) {
    return new Promise<string>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      const options = {
        reportProgress: true,
        observe: "events",
      };

      const req = new HttpRequest("POST", this.API_URL + this.API_SELECTOR_IMAGE, { image: image.serialize(), imageFile: imageFile }, options);

      this.http.request(req).subscribe(
        (event) => {
          console.log("progress: ");
          console.log(event);
          if (event.type == HttpEventType.UploadProgress) {
            const percentDone = Math.round((100 * event.loaded) / event.total);
            console.log(`File is ${percentDone}% loaded.`);
            progress(percentDone);
          } else if (event instanceof HttpResponse) {
            console.log("File is completely loaded!");
            console.log(event.body as any);
            if ((event.body as any).error == 0) {
              resolve((event as HttpResponse<any>).body.imageid);
            } else {
              reject(event.body);
            }
          }
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  updateImage(image: TAGImage, imageFile: string, progress: (progress) => void) {
    return new Promise<string>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      const options = {
        reportProgress: true,
      };

      var body: any = { image: image.serialize() };

      if (imageFile != null) {
        body["imageFile"] = imageFile;
      }

      const req = new HttpRequest("PUT", this.API_URL + this.API_SELECTOR_IMAGE + image._id, body, options);

      this.http.request(req).subscribe(
        (event) => {
          console.log("progress: ");
          console.log(event);
          if (event.type == HttpEventType.UploadProgress) {
            const percentDone = Math.round((100 * event.loaded) / event.total);
            console.log(`File is ${percentDone}% loaded.`);
            progress(percentDone);
          } else if (event instanceof HttpResponse) {
            console.log("File is completely loaded!");
            console.log(event.body as any);
            if ((event.body as any).error == 0) {
              resolve((event as HttpResponse<any>).body.imageid);
            } else {
              reject(event.body);
            }
          }
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  getRandomImage(category: string) {
    return new Promise<TAGImage>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_IMAGE + this.API_SELECTOR_RANDOMIMAGE + (category != "" ? category : "")).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGImage(data.image));
        } else {
          reject(data);
        }
      });
    });
  }

  getImageLink(imageid: string) {
    return this.API_URL + this.API_SELECTOR_IMAGE + imageid + "/" + this.API_SELECTOR_FULLIMAGE;
  }

  getThumbLink(imageid: string) {
    return this.API_URL + this.API_SELECTOR_IMAGE + imageid + "/" + this.API_SELECTOR_THUMBNAIL;
  }

  //#endregion

  //#region OAuth

  checkOAuthClient(client: OAuthAuthorizationRequest) {
    return new Promise<string[]>((resolve, reject) => {
      this.http
        .get(this.API_URL + "oauth/checkClient", {
          params: {
            client_id: client.client_id,
            redirect_uri: client.redirect_uri,
            scope: client.scope,
          },
        })
        .subscribe((data: any) => {
          if (data.valid) {
            resolve([data.permission, data.client_name, data.needsScope]);
          } else {
            reject(data.error);
          }
        });
    });
  }

  acceptOAuthRequest(client: OAuthAuthorizationRequest) {
    if (!this.isLoggedIn()) return Promise.reject("Not logged in");

    return new Promise<string>((resolve, reject) => {
      this.http
        .get(this.API_URL + "oauth/authorize", {
          params: { ...client },
        })
        .subscribe((data: any) => {
          if (data.valid) {
            resolve(data.redirect);
          } else {
            reject(data.error);
          }
        });
    });
  }

  //#endregion

  //#region Schulradio

  setSchulradioBreak(start: boolean) {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.post(this.API_URL + this.API_SELECTOR_SCHULRADIO + "break", { action: start ? "start" : "stop" }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  setSchulradioPlayback(play: boolean) {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.post(this.API_URL + this.API_SELECTOR_SCHULRADIO + "playback", { action: play ? "play" : "pause" }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  skipSchulradioTitle() {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.post(this.API_URL + this.API_SELECTOR_SCHULRADIO + "playback", { action: "skip" }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  initSchulradioPlayerUpdate() {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.post(this.API_URL + this.API_SELECTOR_SCHULRADIO + "updatePlayer", {}).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  //#endregion

  //#region Mail

  getFolders() {
    return new Promise<TAGMailFolder[]>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.get(this.API_URL + "mails/folders").subscribe((data: any) => {
        if (data.error == 0) {
          let folders: TAGMailFolder[] = data.folders.map((folder) => new TAGMailFolder(folder));
          resolve(folders);
        } else {
          reject(data);
        }
      });
    });
  }

  newFolder(folderPath: string) {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.post(this.API_URL + "mails/folder/" + encodeURIComponent(folderPath), {}).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  editFolder(folderPath: string, newFolderPath: string) {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.put(this.API_URL + "mails/folder/" + encodeURIComponent(folderPath) + "/" + encodeURIComponent(newFolderPath), { }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  deleteFolder(folderPath: string) {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.delete(this.API_URL + "mails/folder/" + encodeURIComponent(folderPath)).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  getContacts() {
    return new Promise<TAGMailContact[]>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.get(this.API_URL + "mails/contacts").subscribe((data: any) => {
        if (data.error == 0) {
          let contacts: TAGMailContact[] = data.contacts.map((contact) => new TAGMailContact(contact));
          resolve(contacts);
        } else {
          reject(data);
        }
      });
    });
  }

  getAllMails(mailBoxName: string) {
    return new Promise<TAGMail[]>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.get(this.API_URL + "mails/folder/" + encodeURIComponent(mailBoxName)).subscribe((data: any) => {
        if (data.error == 0) {
          console.log(data);
          resolve(data.mails.map((mail) => new TAGMail(mail)));
        } else {
          reject(data);
        }
      });
    });
  }

  getMailAttachments(mailid: number, filename: string, mailBoxName: string) {
    return new Promise<Blob>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.get(this.API_URL + "mails/folder/" + encodeURIComponent(mailBoxName) + "/" + mailid + "/attachments/" + filename, { responseType: "blob" }).subscribe((data: Blob) => {
        resolve(data);
      });
    });
  }

  markMailRead(mailid: number, read: boolean, mailBoxName: string) {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.put(this.API_URL + "mails/folder/" + encodeURIComponent(mailBoxName) + "/" + mailid + "/read", { read: read }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  markMailFlagged(mailid: number, flagged: boolean, mailBoxName: string) {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.put(this.API_URL + "mails/folder/" + encodeURIComponent(mailBoxName) + "/" + mailid + "/flagged", { flagged: flagged }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  moveMail(mailid: number, srcMailBox: string, dstMailBox: string) {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.put(this.API_URL + "mails/folder/" + encodeURIComponent(srcMailBox) + "/" + mailid + "/move/" + encodeURIComponent(dstMailBox), { }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  deleteMailForever(mailid: number, mailBoxName: string) {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.delete(this.API_URL + "mails/folder/" + encodeURIComponent(mailBoxName) + "/" + mailid).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  sendMail(mail: TAGMail) {
    return new Promise<any>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.post(this.API_URL + "mails", { to: mail.to.serialize(), cc: mail.cc.serialize(), subject: mail.subject, body: mail.html, attachments: mail.attachments.map((a) => a.serialize()) }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(data.info);
        } else {
          reject(data);
        }
      });
    });
  }

  //#endregion

  //#region Inventory Items

  getInvItems() {
    return new Promise<TAGInvItemType[]>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_INVENTORY + this.API_SELECTOR_ITEMS).subscribe((data: any) => {
        if (data.error == 0) {
          let items: TAGInvItemType[] = data.items.map((itm) => {
            return new TAGInvItemType(itm);
          });

          resolve(items);
        } else {
          reject(data);
        }
      });
    });
  }

  findInvItem(idorcode: string) {
    return new Promise<TAGInvItemType>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_INVENTORY + this.API_SELECTOR_ITEMS + "find/" + idorcode).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGInvItemType(data.item));
        } else {
          reject(data);
        }
      });
    });
  }

  getInvItem(id: string) {
    return new Promise<TAGInvItemType>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_INVENTORY + this.API_SELECTOR_ITEMS + id).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGInvItemType(data.item));
        } else {
          reject(data);
        }
      });
    });
  }

  newInvItem(item: TAGInvItemType) {
    return new Promise<TAGInvItemType>((resolve, reject) => {
      this.http.post(this.API_URL + this.API_SELECTOR_INVENTORY + this.API_SELECTOR_ITEMS, { item: item.serialize() }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGInvItemType(data.item));
        } else {
          reject(data);
        }
      });
    });
  }

  updateInvItem(item: TAGInvItemType) {
    return new Promise<TAGInvItemType>((resolve, reject) => {
      this.http.put(this.API_URL + this.API_SELECTOR_INVENTORY + this.API_SELECTOR_ITEMS + item._id, { item: item.serialize() }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGInvItemType(data.item));
        } else {
          reject(data);
        }
      });
    });
  }

  deleteInvItem(id: string) {
    return new Promise<void>((resolve, reject) => {
      this.http.delete(this.API_URL + this.API_SELECTOR_INVENTORY + this.API_SELECTOR_ITEMS + id).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  //#endregion

  //#region Inventory Transactions

  getInvTransactions() {
    return new Promise<TAGInvTransaction[]>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_INVENTORY + this.API_SELECTOR_TRANSACTIONS).subscribe((data: any) => {
        if (data.error == 0) {
          let transactions: TAGInvTransaction[] = data.transactions.map((trans) => {
            return new TAGInvTransaction(trans);
          });

          resolve(transactions);
        } else {
          reject(data);
        }
      });
    });
  }

  getInvTransactionsByItem(itemid: string) {
    return new Promise<TAGInvTransaction[]>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_INVENTORY + this.API_SELECTOR_TRANSACTIONS + this.API_SELECTOR_ITEMS + itemid).subscribe((data: any) => {
        if (data.error == 0) {
          let transactions: TAGInvTransaction[] = data.transactions.map((trans) => {
            return new TAGInvTransaction(trans);
          });

          resolve(transactions);
        } else {
          reject(data);
        }
      });
    });
  }

  getInvTransactionsByEvent(eventid: string) {
    return new Promise<TAGInvTransaction[]>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_INVENTORY + this.API_SELECTOR_TRANSACTIONS + "event/" + eventid).subscribe((data: any) => {
        if (data.error == 0) {
          let transactions: TAGInvTransaction[] = data.transactions.map((trans) => {
            return new TAGInvTransaction(trans);
          });

          resolve(transactions);
        } else {
          reject(data);
        }
      });
    });
  }

  getInvTransaction(id: string) {
    return new Promise<TAGInvTransaction>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_INVENTORY + this.API_SELECTOR_TRANSACTIONS + id).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGInvTransaction(data.transaction));
        } else {
          reject(data);
        }
      });
    });
  }

  createTransaction(transaction: TAGInvTransaction) {
    return new Promise<TAGInvTransaction>((resolve, reject) => {
      this.http.post(this.API_URL + this.API_SELECTOR_INVENTORY + this.API_SELECTOR_TRANSACTIONS, { transaction: transaction.serialize() }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGInvTransaction(data.transaction));
        } else {
          reject(data);
        }
      });
    });
  }

  updateTransaction(transaction: TAGInvTransaction) {
    return new Promise<TAGInvTransaction>((resolve, reject) => {
      this.http.put(this.API_URL + this.API_SELECTOR_INVENTORY + this.API_SELECTOR_TRANSACTIONS + transaction._id, { transaction: transaction.serialize() }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGInvTransaction(data.transaction));
        } else {
          reject(data);
        }
      });
    });
  }

  addTransactionItems(transactionID: string, items: { itemid: string; instanceid: string }[]) {
    return new Promise<TAGInvTransaction>((resolve, reject) => {
      this.http.put(this.API_URL + this.API_SELECTOR_INVENTORY + this.API_SELECTOR_TRANSACTIONS + transactionID + "/add", { items: items }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGInvTransaction(data.transaction));
        } else {
          reject(data);
        }
      });
    });
  }

  returnTransactionItems(transactionID: string, items: { itemid: string; instanceid: string }[]) {
    return new Promise<TAGInvTransaction>((resolve, reject) => {
      this.http.put(this.API_URL + this.API_SELECTOR_INVENTORY + this.API_SELECTOR_TRANSACTIONS + transactionID + "/back", { items: items }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGInvTransaction(data.transaction));
        } else {
          reject(data);
        }
      });
    });
  }

  //#endregion

  //#region Inventory Groups

  getInvItemGroups() {
    return new Promise<TAGInvItemGroup[]>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_INVENTORY + this.API_SELECTOR_GROUPS).subscribe((data: any) => {
        if (data.error == 0) {
          let groups: TAGInvItemGroup[] = data.groups.map((grp) => {
            return new TAGInvItemGroup(grp);
          });
          resolve(groups);
        } else {
          reject(data);
        }
      });
    });
  }

  getInvItemGroup(id: string) {
    return new Promise<TAGInvItemGroup>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_INVENTORY + this.API_SELECTOR_GROUPS + id).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGInvItemGroup(data.group));
        } else {
          reject(data);
        }
      });
    });
  }

  createInvItemGroup(group: TAGInvItemGroup) {
    return new Promise<TAGInvItemGroup>((resolve, reject) => {
      this.http.post(this.API_URL + this.API_SELECTOR_INVENTORY + this.API_SELECTOR_GROUPS, { group: group.serialize() }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGInvItemGroup(data.group));
        } else {
          reject(data);
        }
      });
    });
  }

  updateInvItemGroup(group: TAGInvItemGroup) {
    return new Promise<TAGInvItemGroup>((resolve, reject) => {
      this.http.put(this.API_URL + this.API_SELECTOR_INVENTORY + this.API_SELECTOR_GROUPS + group._id, { group: group.serialize() }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGInvItemGroup(data.group));
        } else {
          reject(data);
        }
      });
    });
  }

  deleteInvItemGroup(id: string) {
    return new Promise<void>((resolve, reject) => {
      this.http.delete(this.API_URL + this.API_SELECTOR_INVENTORY + this.API_SELECTOR_GROUPS + id).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  //#endregion

  //#region Finance Transactions

  getFinanceTransactions() {
    return new Promise<TAGTransaction[]>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_FINANCES + this.API_SELECTOR_TRANSACTIONS).subscribe((data: any) => {
        if (data.error == 0) {
          let transactions: TAGTransaction[] = data.transactions.map((trans) => {
            return new TAGTransaction(trans);
          });

          resolve(transactions);
        } else {
          reject(data);
        }
      });
    });
  }

  getFinanceTransactionsByUser(user: string) {
    return new Promise<TAGTransaction[]>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_FINANCES + this.API_SELECTOR_TRANSACTIONS + this.API_SELECTOR_USER + user).subscribe((data: any) => {
        if (data.error == 0) {
          let transactions: TAGTransaction[] = data.transactions.map((trans) => {
            return new TAGTransaction(trans);
          });

          resolve(transactions);
        } else {
          reject(data);
        }
      });
    });
  }

  getFinanceTransactionsByEvent(eventid: string) {
    return new Promise<TAGTransaction[]>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_FINANCES + this.API_SELECTOR_TRANSACTIONS + "event/" + eventid).subscribe((data: any) => {
        if (data.error == 0) {
          let transactions: TAGTransaction[] = data.transactions.map((trans) => {
            return new TAGTransaction(trans);
          });

          resolve(transactions);
        } else {
          reject(data);
        }
      });
    });
  }

  getFinanceTransaction(id: string) {
    return new Promise<TAGTransaction>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_FINANCES + this.API_SELECTOR_TRANSACTIONS + id).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGTransaction(data.transaction));
        } else {
          reject(data);
        }
      });
    });
  }

  getNextInvoiceNumber() {
    return new Promise<number>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_FINANCES + "invoice/next").subscribe((data: any) => {
        if (data.error == 0) {
          resolve(parseInt(data.next));
        } else {
          reject(data);
        }
      });
    });
  }

  newFinanceTransaction(transaction: TAGTransaction) {
    return new Promise<TAGTransaction>((resolve, reject) => {
      this.http.post(this.API_URL + this.API_SELECTOR_FINANCES + this.API_SELECTOR_TRANSACTIONS, { transaction: transaction.serialize() }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGTransaction(data.transaction));
        } else {
          reject(data);
        }
      });
    });
  }

  updateFinanceTransaction(transaction: TAGTransaction) {
    return new Promise<TAGTransaction>((resolve, reject) => {
      this.http.put(this.API_URL + this.API_SELECTOR_FINANCES + this.API_SELECTOR_TRANSACTIONS + transaction._id, { transaction: transaction.serialize() }).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGTransaction(data.transaction));
        } else {
          reject(data);
        }
      });
    });
  }

  deleteFinanceTransaction(transactionID: string) {
    return new Promise<void>((resolve, reject) => {
      this.http.delete(this.API_URL + this.API_SELECTOR_FINANCES + this.API_SELECTOR_TRANSACTIONS + transactionID).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  getAllFinanceTransactionAttachments(transactionID: string) {
    return new Promise<{fileName: string, mimeType: string}[]>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_FINANCES + this.API_SELECTOR_TRANSACTIONS + transactionID + "/file").subscribe((data: any) => {
        if (data.error == 0) {
          resolve(data.files);
        } else {
          reject(data);
        }
      });
    });
  }

  getPublicFinanceTransactionInvoice(transactionID: string) {
    return new Promise<Blob>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_FINANCES + this.API_SELECTOR_TRANSACTIONS + transactionID + "/file/invoice", { responseType: "blob" }).subscribe((data: Blob) => {
        resolve(data);
      });
    });
  }

  getFinanceTransactionAttachment(transactionID: string, fileName: string) {
    return new Promise<Blob>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_FINANCES + this.API_SELECTOR_TRANSACTIONS + transactionID + "/file/" + fileName, { responseType: "blob" }).subscribe((data: Blob) => {
        resolve(data);
      });
    });
  }

  uploadFinanceTransactionAttachment(transactionID: string, fileName: string, fileData: string, progress: (progress) => void) {
    return new Promise<TAGTransaction>((resolve, reject) => {
      const options = {
        reportProgress: true,
        observe: "events",
      };

      const req = new HttpRequest("POST", this.API_URL + this.API_SELECTOR_FINANCES + this.API_SELECTOR_TRANSACTIONS + transactionID + "/file/" , { fileName: fileName, file: fileData }, options);

      this.http.request(req).subscribe(
        (event) => {
          console.log("progress: ");
          console.log(event);
          if (event.type == HttpEventType.UploadProgress) {
            const percentDone = Math.round((100 * event.loaded) / event.total);
            console.log(`File is ${percentDone}% loaded.`);
            progress(percentDone);
          } else if (event instanceof HttpResponse) {
            console.log("File is completely loaded!");
            console.log(event.body as any);
            if ((event.body as any).error == 0) {
              resolve(new TAGTransaction((event as HttpResponse<any>).body.transaction));
            } else {
              reject(event.body);
            }
          }
        },
        (err: any) => {
          reject(err);
        }
      );
    });
  }

  deleteFinanceTransactionAttachment(transactionID: string, fileName: string) {
    return new Promise<TAGTransaction>((resolve, reject) => {
    this.http.delete(this.API_URL + this.API_SELECTOR_FINANCES + this.API_SELECTOR_TRANSACTIONS + transactionID + "/file/" + fileName).subscribe((data: any) => {
      if (data.error == 0) {
        resolve(new TAGTransaction(data.transaction));
      } else {
        reject(data);
      }
    });
  });
  }

  //#endregion

  //#region Locations

  getLocations() {
    return new Promise<TAGLocation[]>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_LOCATIONS).subscribe((data: any) => {
        if (data.error == 0) {
          let locations : TAGLocation[] = data.locations.map(location => {
            return new TAGLocation(location);
          });

          resolve(locations);
        } else {
          reject(data);
        }
      });
    });
  }

  getLocation(locationid: string) {
    return new Promise<TAGLocation>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.get(this.API_URL + this.API_SELECTOR_LOCATIONS + locationid).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(new TAGLocation(data.location));
        } else {
          reject(data);
        }
      });
    });
  }

  newLocation(location: TAGLocation, imageFile: string) {
    return new Promise<string>((resolve, reject) => {
      this.http.post(this.API_URL + this.API_SELECTOR_LOCATIONS, {location: location.serialize(), image: imageFile}).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(data.locationid);
        } else {
          reject(data);
        }
      });
    });
  }

  updateLocation(location: TAGLocation, imageFile: string) {
    return new Promise<string>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.put(this.API_URL + this.API_SELECTOR_LOCATIONS + location._id, {location: location.serialize(), image: imageFile}).subscribe((data: any) => {
        if (data.error == 0) {
          resolve(data.locationid);
        } else {
          reject(data);
        }
      });
    });
  }

  deleteLocation(locationid: string) {
    return new Promise<void>((resolve, reject) => {
      if (!this.isLoggedIn()) reject();

      this.http.delete(this.API_URL + this.API_SELECTOR_LOCATIONS + locationid).subscribe((data: any) => {
        if (data.error == 0) {
          resolve();
        } else {
          reject(data);
        }
      });
    });
  }

  getLocationImage(locationid: string) {
    return new Promise<string>((resolve, reject) => {
      this.http.get(this.API_URL + this.API_SELECTOR_LOCATIONS + locationid + "/image").subscribe((data: any) => {
        resolve("data:image/jpeg;base64," + data);
      });
    });
  }

  getLocationImageLink(locationid: string) {
    return this.API_URL + this.API_SELECTOR_LOCATIONS + locationid + "/image";
  }

  //#endregion
}
