<h1 mat-dialog-title>{{ "musicwish.similar.title" | translate }}</h1>

<div mat-dialog-content>
  <div>{{ "musicwish.similar.message" | translate:data }}</div>

  <div class="musicwish-item">
    <span class="mwi-title">{{data.similar.title}}</span>
    <span class="mwi-artist">{{data.similar.artist}}</span>
    <span class="mwi-votes">{{data.similar.votes}}</span>
  </div>
</div>

<div mat-dialog-actions>
  <button mat-button [mat-dialog-close]="false" cdkFocusInitial>{{ "general.no" | translate }}</button>
  <button mat-button [mat-dialog-close]="true">{{ "general.yes" | translate }}</button>
</div>
