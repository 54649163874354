<div *ngIf="!showSuccess" id="form-wrapper">
    <span id="form-title">{{ "requests.title" | translate }}</span>
    <span id="form-description">{{ "requests.description" | translate }}</span>

    <form [formGroup]="requestForm" (submit)="submitForm()">
        <mat-form-field appearance="outline">
            <mat-label>{{ "internal.requests.name" | translate }}</mat-label>
            <input matInput formControlName="name"/>
            <mat-icon matSuffix>info</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ 'internal.requests.location' | translate }}</mat-label>
            <input matInput placeholder="Location" formControlName="location" [matAutocomplete]="locationAutocomplete" />
            <mat-icon matSuffix>location_on</mat-icon>

            <mat-autocomplete #locationAutocomplete="matAutocomplete">
            <mat-option *ngFor="let loc of filteredLocations | async" [value]="loc.name"> {{loc.name}} </mat-option>
            </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ "internal.requests.contact" | translate }}</mat-label>
            <input matInput formControlName="contact"/>
            <mat-icon matSuffix>person</mat-icon>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ "internal.requests.contactMail" | translate }}</mat-label>
            <input matInput formControlName="contactMail"/>
            <mat-icon matSuffix>mail</mat-icon>
        </mat-form-field>

        <!-- checkbox: "include this event on the technikflg.com website?" event entweder listed (checked) oder unlisted (not checked) -->

        <mat-form-field appearance="outline">
            <mat-label>{{ "internal.requests.startdate" | translate }}</mat-label>
            <input matInput formControlName="startdate" [min]="today" [max]="max" [matDatetimepicker]="startdatepicker" />
            <mat-datetimepicker-toggle [for]="startdatepicker" matSuffix></mat-datetimepicker-toggle>
            <mat-datetimepicker #startdatepicker type="datetime" openOnFocus="true" mode="landscape" timeInterval="5"></mat-datetimepicker>
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>{{ "internal.requests.enddate" | translate }}</mat-label>
            <input matInput formControlName="enddate" [min]="this.requestForm.controls['startdate'].value" [max]="max" [matDatetimepicker]="enddatepicker" />
            <mat-datetimepicker-toggle [for]="enddatepicker" matSuffix></mat-datetimepicker-toggle>
            <mat-datetimepicker #enddatepicker type="datetime" openOnFocus="true" mode="landscape" timeInterval="5"></mat-datetimepicker>
        </mat-form-field>

        <mat-form-field class="fullwidth" appearance="outline">
            <mat-label>{{ "internal.requests.equipment" | translate }}</mat-label>
            <textarea matInput formControlName="equipment" ></textarea>
            <mat-icon matSuffix>memory</mat-icon>
        </mat-form-field>

        <button mat-raised-button color="primary" [disabled]="!requestForm.valid || isLoading" type="submit">{{ "requests.submit" | translate }}</button>
    </form>
</div>
<div class="request-form-spacer"></div>

<div *ngIf="showSuccess" id="form-wrapper">
    <span id="form-title">{{ "requests.successTitle" | translate }}</span>
    <span id="form-description">{{ "requests.successDescription" | translate }}</span>
</div>