<div id="invcheck-header">
  <div id="invcheck-header-title">{{ 'internal.inventory.inventoryCheck.title' | translate }}</div>
</div>
<div id="invcheck-form">
  <form [formGroup]="scannerForm" (ngSubmit)="scanCode()">
    <mat-form-field>
      <input #scannerInput matInput placeholder="{{ 'internal.inventory.scanner' | translate }}" formControlName="input" />
      <button type="button" mat-icon-button matSuffix (click)="openCameraScanner()"><mat-icon>camera_alt</mat-icon></button>
    </mat-form-field>
  </form>

  <div id="item-list">
    <div id="item-list-header">
      <div id="item-list-header-title">{{ 'internal.inventory.items' | translate }}</div>
    </div>
    <div id="hspacer"></div>
    <div id="item-list-content">
      <div *ngFor="let item of filteredItems()" class="item" [ngClass]="{returned: itemComplete(item._id)}">
        <div class="item-name">{{item.name}}</div>
        <div class="instance-list" *ngIf="!itemComplete(item._id)">
          <div *ngFor="let instance of filteredInstances(item._id)" class="instance" [ngClass]="{returned: instanceFound(item._id, instance.itemid), booked: instanceBooked(item._id, instance.itemid) && !instanceFound(item._id, instance.itemid)}">
            <button mat-icon-button (click)="printLabel(item, instance)"><mat-icon>print</mat-icon></button>
            <div class="instance-name">{{item.name}}</div>
            <div *ngIf="item.additionalInfo" class="instance-addInfo">{{item.additionalInfo}}</div>
            <div class="instance-id">{{instance.itemid}}</div>
            <div *ngIf="instance.deleted || item.deleted" class="instance-status">{{ 'internal.inventory.deleted' | translate }}</div>
            <div class="instance-status">{{instanceStatus(item._id, instance.itemid) | translate}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div id="invcheck-actions">
  <button mat-stroked-button color="warn" (click)="clearCheckpoint()">{{ 'internal.inventory.inventoryCheck.clearCheckpoint' | translate }}</button>
  <button mat-stroked-button color="warn" (click)="cancel()">{{ 'general.cancel' | translate }}</button>
  <button mat-stroked-button (click)="save()">{{ 'general.save' | translate }}</button>
</div>
