import { Component, Inject } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ApiService } from "../api.service";
import { TAGInvTransaction } from "../models/taginventory";

export interface InvTransactionImportDialogData {

}

@Component({
  selector: "int-inv-transaction-import-dialog",
  templateUrl: "InvTransactionImportDialog.html",
  styleUrls: ["./InvTransactionImportDialog.scss"],
})
export class InvTransactionImportDialog {

  transactions: TAGInvTransaction[] = [];

  constructor(
    private api: ApiService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<InvTransactionImportDialogData>,
    @Inject(MAT_DIALOG_DATA) public data: InvTransactionImportDialogData
  ) {

  }

  ngOnInit() {
    this.api.waitForAPI().then(() => {
      this.api.getInvTransactions().then((result) => {
        this.transactions = result.filter((t) => t.event == "");

        this.transactions.sort((a, b) => {
          return a.startdate > b.startdate ? -1 : 1;
        });
      }).catch((reason) => {

      });
    });
  }

  selectedTransaction(transaction: TAGInvTransaction) {
    this.dialogRef.close(transaction);
  }
}
