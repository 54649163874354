import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TAGInvTransaction } from "../models/taginventory";
import { ApiService } from "../api.service";
import { TAGEvent } from "../models/tagevent";

export interface TransactionCreateDialogData {
  eventid?: string;
  transactionName?: string;
}

@Component({
  selector: "int-inventory-transactioncreatedialog",
  templateUrl: "transactionCreateDialog.html",
  styleUrls: ["./transactionCreateDialog.scss"],
})
export class TransactionCreateDialog {

  transaction: TAGInvTransaction = new TAGInvTransaction({});
  events: TAGEvent[] = [];

  constructor(public dialogRef: MatDialogRef<TransactionCreateDialog>, @Inject(MAT_DIALOG_DATA) public data: TransactionCreateDialogData, private api: ApiService) {
    if (data.eventid) this.transaction.event = data.eventid;
    if (data.transactionName) this.transaction.location = data.transactionName;

    this.dialogRef.disableClose = true;

    console.log(this.transaction);
    console.log(data);
  }

  ngOnInit(): void {
    this.api.waitForAPI().then(() => {
      this.api.getEvents().then((events) => {
        this.events = events;
        console.log(this.events);
      });
    });
  }

  cancelEdit(): void {
    this.dialogRef.close(null);
  }

  saveEdit(): void {
    this.dialogRef.close(this.transaction);
  }
}
