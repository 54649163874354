import { Component, OnInit, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { TAGMusicWish } from "../models/tagmusicwish";
import { openDeleteDialog } from "../dialogs/deleteDialog/deleteDialog";
import { ApiService } from "../api.service";
import { Permission } from "../models/tagpermission";
import { MatSort } from "@angular/material/sort";
import { MatPaginator } from "@angular/material/paginator";
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from "@ngx-translate/core";

/**
 * TODO:
 * TranslateService kinda sus imo
 */

@Component({
  selector: "app-internalMusicwishes",
  templateUrl: "./internal.musicwishes.component.html",
  styleUrls: ["./internal.musicwishes.component.scss"],
})
export class InternalMusicwishesComponent implements OnInit {
  @ViewChild(MatSort, { static: false }) sort: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  pageSize: number = 25;
  currentPage: number = 0;
  displayedColumns: string[] = ["owner", "title", "artist", "votes", "played"];
  dataSource: MatTableDataSource<TAGMusicWish> = new MatTableDataSource();

  musicwishes: TAGMusicWish[] = [];

  reloadTimer: any = undefined;

  constructor(private api: ApiService, public dialog: MatDialog, public snackBar: MatSnackBar, private translateService: TranslateService) { }

  wishesEnabled(): boolean {
    return this.api.config.enableMusicWishes;
  }

  hasPerm(permission: string): boolean {
    return this.api.hasPermission(Permission.fromName(permission));
  }

  ngOnInit() {
    this.api.waitForAPI().then(() => {
      if (this.hasPerm("delete_musicwishes")) {
        this.displayedColumns.push("delete")
      }

      this.reloadMusicWishes();
    });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.dataSource.data = this.musicwishes;
  }
  ngOnDestroy(): void {
    if (this.reloadTimer) clearInterval(this.reloadTimer);
  }

  toggleAutoReload(event) {
    if (event.checked) {
      if (this.reloadTimer) clearInterval(this.reloadTimer);
      this.reloadTimer = setInterval(() => {
        this.reloadMusicWishes();
      }, 1000);
    } else {
      if (this.reloadTimer) clearInterval(this.reloadTimer);
      this.reloadTimer = undefined;
    }
  }

  reloadMusicWishes() {
    this.api.getMusicWishes().then((result) => {
      this.musicwishes = result;
      // Fallback sorting if MatSort is not selected, first by songs played, then by voices.
      this.musicwishes.sort((a, b) => {
        if (a.played && !b.played) {
          return 1;
        } else if (!a.played && b.played) {
          return -1;
        } else {
          return b.votes - a.votes;
        }
      }); 
      this.dataSource.data = this.musicwishes;
    }).catch((reason) => {
      this.musicwishes = [];
      this.dataSource.data = this.musicwishes;
    });
  }

  switchPage(event) {
    this.currentPage = event.pageIndex;
    this.pageSize = event.pageSize;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  toggleMusicWishPlayed(musicwish: TAGMusicWish) {
    musicwish.played = !musicwish.played;

    this.api.updateMusicWish(musicwish).then((result) => {
      console.log(result);
      this.reloadMusicWishes();
    }).catch((reason) => {
      console.log(reason);
      //TODO: Show Error
    });
  }

  deleteMusicWish(musicwish: TAGMusicWish) {
    if (!this.hasPerm("delete_musicwishes")) return;

    openDeleteDialog(this.dialog, "Music Wish", [musicwish.title])
      .afterClosed()
      .subscribe((result) => {
        if (result)
          this.api.deleteMusicWishes(musicwish._id).then((result) => {
            this.musicwishes.splice(this.musicwishes.indexOf(musicwish), 1);
            this.reloadMusicWishes();
          }).catch((reason) => {
            //TODO: Show Error
          });
      });
  }

  copyMusicWish(musicwish: TAGMusicWish) {
    navigator.clipboard.writeText(musicwish.artist + " " + musicwish.title)
      .then(() => {
        this.snackBar.open(this.translateService.instant("internal.musicwishes.copy.copied"), this.translateService.instant("internal.musicwishes.copy.close"), {
          duration: 1000,
        });
      })
      .catch(err => {
        this.snackBar.open(this.translateService.instant("internal.musicwishes.copy.failed"), this.translateService.instant("internal.musicwishes.copy.close"), {
          duration: 3000,
        });
        console.error('Failed to copy text: ', err);
      });
  }

  deleteAllMusicwishes() {
    openDeleteDialog(this.dialog, "Music Wish", ["All Musicwishes"])
      .afterClosed()
      .subscribe((result) => {
        if (result)
          this.api.deleteAllMusicWishes().then((result) => {
            this.reloadMusicWishes();
          }).catch((reason) => {
            //TODO: Show Error
          });
      });
  }
}
