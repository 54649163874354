<div id="transactionCreate-header">
  <div id="transactionCreate-header-title">{{ 'internal.inventory.transactionCreate.title' | translate }}</div>
</div>

<div id="transactionCreate-form">
  <mat-form-field appearance="standard">
    <mat-label>{{ 'internal.inventory.transactionCreate.location' | translate }}</mat-label>
    <input matInput placeholder="Gymnastikhalle" [(ngModel)]="transaction.location" />
    <mat-icon matSuffix>edit</mat-icon>
  </mat-form-field>

  <mat-form-field appearance="standard">
    <mat-label>{{ 'internal.inventory.transactionCreate.event' | translate }}</mat-label>
    <mat-select [(ngModel)]="transaction.event">
      <mat-option [value]="undefined">{{ 'internal.inventory.transactionCreate.eventNone' | translate }}</mat-option>
      <mat-option *ngFor="let event of events" [value]="event._id">
        {{event.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div id="transactionCreate-actions">
  <button mat-stroked-button color="warn" (click)="cancelEdit()">{{ 'general.cancel' | translate }}</button>
  <button mat-stroked-button [disabled]="transaction.location.trim() == ''" (click)="saveEdit()">{{ 'general.save' | translate }}</button>
</div>
